<script lang="ts">
	import Paginator from "./Paginator.svelte";
	import PostForm from "./PostForm.svelte";

	let searchParams = new URLSearchParams(location.search);
	let showUser = searchParams.get("show-user");
</script>

<style>
	.main-header, h1, h4 {
		text-align: center;
		margin: 4px;
	}

	.orange {
		color: #F4A15D;
		text-shadow: 1px 1px black;
	}

	.create-post {
		text-align: center;
	}
</style>

<h1 class="main-header">Welcome to <br>☁️ <span class="orange">CloudSocial</span> ☁️</h1>
<hr>

{#if showUser == null}
	<div class="create-post">
		<PostForm/>
	</div>
	<hr>

	<div>
		<h1>Latest posts</h1>
		<Paginator basePath="/posts"/>
	</div>
{:else}
	<div>
		<h1>Posts by <b>{showUser}</b></h1>
		<h4>Back to <a href="/">all posts</a></h4>
		<hr>
		<Paginator basePath="/user/{showUser}"/>
	</div>
{/if}
