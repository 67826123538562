<script lang="ts">
	import CONFIG from "./config.ts";
	import Post from "./Post.svelte";
	import FakeLink from "./FakeLink.svelte";

	export let basePath: string;

	let cursor: string = null;
	let morePages = false;
	let posts = [];
	let offsetMargins = new Map();

	let fetchInProgress = false;
	let finishedLoad = false;

	let MAX_OFFSET_LR;
	let onResize = () => MAX_OFFSET_LR = innerWidth - 500;
	addEventListener("resize", onResize);
	onResize();

	function genOffset(id: string): void {
		if (!offsetMargins.has(id)) {
			offsetMargins.set(id, Math.random() * (MAX_OFFSET_LR * 2) - MAX_OFFSET_LR);
		}
	}

	window.addEventListener("prependPost", function(e) {
		genOffset(e.post.id);
		posts = [e.post, ...posts];
	});

	function fetchPage(): void {
		if (fetchInProgress || (finishedLoad && !morePages)) {
			return;
		}
		fetchInProgress = true;

		let cursorParam = "";
		if (cursor != null) {
			cursorParam = `?cursor=${cursor}`;
		}

		let resolved = true;

		fetch(`${CONFIG.API_HOST}${basePath}${cursorParam}`)
			.then(resp => {
				if (resp.ok) {
					return resp.json();
				} else {
					resolved = false;
					return resp.text();
				}
			})
			.then(data => {
				if (!resolved) {
					throw new Error(data);
				}
				finishedLoad = true;
				if ("user_exists" in data && !data.user_exists) {
					morePages = false;
					return;
				}
				morePages = data.have_pages_remaining;
				cursor = data.cursor;
				if (data.posts.length != 0) {
					posts = posts.concat(data.posts);
				}

				for (const i of posts) {
					genOffset(i.id);
				}
			})
			.catch(e => alert(`Worker ${e.message}`))
			.finally(() => fetchInProgress = false);
	}

	fetchPage();
</script>

<style>
	.wrapper {
		text-align: center;
	}

	.message {
		color: #3E84B8;
		font-size: 30px;
		font-weight: bold;
	}
</style>

<div class="wrapper">
	{#if !finishedLoad}
		<div class="message">LOADING...</div>
	{/if}

	{#each posts as i (i.id)}
		<Post username={i.post.username} title={i.post.title} content={i.post.content} timestamp={i.timestamp} imageData={i.post.image} offsetMargin={offsetMargins.get(i.id)}/>
	{/each}

	{#if finishedLoad && posts.length == 0}
		<div class="message">No posts to show.</div>
	{/if}

	<FakeLink text="Show more" enabled={morePages} on:click={fetchPage}/>
</div>
