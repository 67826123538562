<script type="ts">
	export let enabled = true;
	export let text: string;
</script>

<style>
	.enabled {
		color: #066597;
	}

	.disabled {
		color: #767676;
		cursor: not-allowed;
	}

	span {
		text-decoration: underline;
		cursor: pointer;
		font-size: 25px;
		font-weight: bold;
	}
</style>

<span class={enabled ? "enabled" : "disabled"} on:click>{text}</span>
