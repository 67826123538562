<script type="ts">
	import CONFIG from "./config.ts";
	import { onMount } from "svelte";

	export let username: string;
	export let title: string;
	export let content: string;
	export let timestamp: number;
	export let offsetMargin: number;
	export let imageData: string = null;

	let wrapper: HTMLElement;

	onMount(() => {
		wrapper.style.marginLeft = `${offsetMargin}px`;
	});
</script>

<style>
	.wrapper {
		margin: 12px;
		margin-left: px;
	}

	.content-box {
		padding: 10px;
		padding-bottom: 40px;
		border-radius: 90px;
		background-color: #ECEFF1;
		display: inline-block;
		box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
		color: #151515;
		width: 450px;
	}

	.puffs {
		margin-top: 84px;
		background-color: none;
		border-radius: 100px;
		margin-bottom: -160px;
	}

	.puffs::before, .puffs::after {
		z-index: -1;
		content: "";
		position: relative;
		display: inline-block;
		background-color: #ECEFF1;
		border-radius: inherit;
	}

	.puffs::after {
		width: 100px;
		height: 100px;
		top: -110px;
		left: -170px;
	}

	.puffs::before {
		width: 180px;
		height: 180px;
		top: -64px;
		left: 90px;
	}

	.content-holder {
		text-align: center;
	}

	h3, h5 {
		margin: 3px;
	}

	.line {
		border-top: 1px dotted black;
		margin: 8px 40px;
	}
</style>

<div class="wrapper" bind:this={wrapper}>
	<div class="puffs"></div>
	<div class="content-box">
		<h3>{title}</h3>
		<h5>Posted by: <a href="/?show-user={encodeURIComponent(username)}">{username}</a></h5>
		<h5>{new Date(timestamp).toLocaleString()}</h5>
		<div class="line"></div>
		{#if imageData != null && imageData.startsWith("data:image/png;base64,")}
			<img src={imageData} style="max-width: {CONFIG.MAX_IMAGE_WIDTH}px; max-height: {CONFIG.MAX_IMAGE_HEIGHT}px;">
		{/if}
		<pre class="content-holder">{content}</pre>
	</div>
</div>
