<script type="ts">
	import CONFIG from "./config.ts";
	import { onMount } from "svelte";

	let authorInput: HTMLElement;
	let titleInput: HTMLElement;
	let textarea: HTMLElement;
	let imageInput: HTMLElement;

	let cvs: HTMLElement;
	let ctx;
	onMount(() => ctx = cvs.getContext("2d"));

	let haveImageData = false;
	let imageDataURL: string = null;

	function processImageUpload(): void {
		if (imageInput.files.length == 1) {
			createImageBitmap(imageInput.files[0].slice())
				.then(bm => {
					let scaleFactor = Math.min(CONFIG.MAX_IMAGE_WIDTH / bm.width, CONFIG.MAX_IMAGE_HEIGHT / bm.height);
					cvs.width = bm.width * scaleFactor;
					cvs.height = bm.height * scaleFactor;
					ctx.clearRect(0, 0, cvs.width, cvs.height);
					ctx.drawImage(bm, 0, 0, cvs.width, cvs.height);
					imageDataURL = cvs.toDataURL();
					haveImageData = true;
				})
		} else {
			alert("A file wasn't selected.");
		}
	}

	let postInProgress = false;

	function submitPost(): void {
		if (postInProgress) {
			return;
		}
		postInProgress = true;
		let author = authorInput.value.trim();
		let title = titleInput.value.trim();
		let content = textarea.innerText.trim();

		let valid = true;
		let resolved = true;

		if (author == "") {
			authorInput.classList.add("invalid-input");
			valid = false;
		}
		if (title == "") {
			titleInput.classList.add("invalid-input");
			valid = false;
		}
		if (content == "") {
			textarea.classList.add("invalid-input");
			valid = false;
		}

		if (valid) {
			authorInput.classList.remove("invalid-input");
			titleInput.classList.remove("invalid-input");
			textarea.classList.remove("invalid-input");

			fetch(`${CONFIG.API_HOST}/posts`, {
				method: "POST",
				body: JSON.stringify({
					title: title,
					username: author,
					content: content,
					image: haveImageData ? imageDataURL : null,
				})
			})
			.then(resp => {
				if (resp.ok) {
					return resp.json();
				} else {
					resolved = false;
					return resp.text();
				}
			})
			.then(result => {
				if (!resolved) {
					throw new Error(result);
				}
				textarea.innerText = "";
				titleInput.value = "";
				removeImage();
				let evt = new Event("prependPost");
				evt.post = result;
				window.dispatchEvent(evt);
			})
			.catch(e => alert(`Worker ${e.message}`))
			.finally(() => postInProgress = false);
		}
	}

	function removeImage(): void {
		imageDataURL = null;
		haveImageData = false;
		cvs.width = 0;
		cvs.height = 0;
	}
</script>

<style>
	input[type="text"] {
		width: 12em;
		border: none;
		border-radius: 10px;
		margin: 2px;
		padding: 4px;
		display: inline-block;
		margin-bottom: 4px;
		border: 2px solid gray;
	}

	input[type="text"]::placeholder {
		text-align: center;
	}

	.wrapper {
		display: inline-block;
		text-align: center;
		width: 600px;
		padding: 0 20px;
	}

	.spacer {
		display: inline-block;
		width: 8px;
	}

	h1 {
		margin: 4px;
	}

	.textarea {
		border-radius: 40px;
		padding: 30px;
		width: 400px;
		overflow-x: auto;
		height: auto;
		min-height: 200px;
		background-color: white;
		color: black;
		margin: 0 auto;
		text-align: left;
		border: 2px solid gray;
	}

	#uploader {
		display: none;
	}

	label[for="uploader"], button {
		border: none;
		color: white;
		cursor: pointer;
		display: inline-block;
		margin: 10px;
		background-color: #6297D6;
		padding: 6px;
		border-radius: 7px;
		font-size: 16px;
	}

	.img-canvas {
		display: block;
		margin: 6px auto;
	}
</style>

<div class="wrapper">
	<h1>Create post</h1>
	<input type="text" placeholder="Title" bind:this={titleInput}>
	<span class="spacer"></span>
	<input type="text" placeholder="Username" bind:this={authorInput}>
	<br>
	{#if haveImageData}
		<button on:click={removeImage}>Remove image</button>
	{:else}
		<label for="uploader">Upload an image</label>
		<input type="file" id="uploader" accept="image/png, image/jpeg" class="image-upload" bind:this={imageInput} on:input={processImageUpload}>
	{/if}
	<canvas class="img-canvas" width="0" height="0" bind:this={cvs}></canvas>
	<div class="textarea" contenteditable bind:this={textarea}></div>
	<button on:click={submitPost}>Create Post!</button>
</div>
